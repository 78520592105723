import { type ExposeStimulus, StimulusControllerBase } from './base_controller'
import { DOMEmit, DOMListen, IsHTMLElement } from '../features/dom_utils'
import ClipsApi from '@api/ClipsApi'
import { renderEditorStyles } from '../features/editor/styles'
import { useTemplateVersionStore } from '@stores/generic/templateVersion.store'
import { getActivePinia } from 'pinia'
import { toggleSourceContexts } from '~/features/questionnaire/utils/ToggleDocument'

interface SyncResponse {
  agreement_html?: string
}
export interface ToggleSplitController
  extends ExposeStimulus<typeof ToggleSplitController> {}
export class ToggleSplitController extends StimulusControllerBase {
  static values = {
    clipId: Number
  } as const

  connect() {
    this.listenOnToggleSplit()
    DOMListen('document-conditions-update', () => {
      if(!this.activeEditorIds.includes(this.currentOpenEditorId)) this.handleEditors()
      if(this.currentOpenEditor) this.handleArrows(this.currentOpenEditor)
    })

    DOMListen('questionnaire:answers-changed', () => this.setSyncLabel('needs_sync'))
  }

  listenOnToggleSplit(){
    window.addEventListener('onToggleSplitToggle', () => {
      setTimeout(() => {
        const editor = document.querySelector<HTMLElement>(
          '[data-start-editor="true"]'
        ) || this.activeEditors[0]
        editor.classList.remove('hidden')
        if(this.isActiveDocument(editor)) this.emitSelection(editor)
        this.handleArrows(editor)
      }, 0)
    }, {once: true})
  }

  moveForward(e: MouseEvent) {
    this.move(1, e)
  }

  moveBack(e: MouseEvent) {
    this.move(-1, e)
  }

  move(direction: -1 | 1, e: MouseEvent) {
    if (
      e.target instanceof HTMLElement &&
      e.target.classList.contains('disabled')
    )
      return
    const currentEditor = document.querySelector(
      "[id*='q-editor']:not(.hidden)"
    )!
    const availableEditors = currentEditor.parentElement!.querySelectorAll(
      "[id*='q-editor'][data-active='true'], [id*='q-editor']:not(.hidden)"
    )
    const availableEditorIds = Array.from(availableEditors).map((e) => e.id)
    const otherEditorId =
      availableEditorIds[
        availableEditorIds.indexOf(currentEditor.id) + direction
      ]
    const otherEditor = document.getElementById(otherEditorId)
    if (
      !currentEditor ||
      !otherEditor ||
      !otherEditor.classList.contains('avv-editor')
    ) {
      return
    }
    currentEditor.classList.toggle('hidden')
    otherEditor.classList.toggle('hidden')
    this.handleArrows(otherEditor)
    this.emitSelection(otherEditor)

    const templateVersionStore = useTemplateVersionStore(getActivePinia())
    templateVersionStore
      .hydrateById(+otherEditor.dataset.templateVersionId!, ['docx_settings'], true)
      .then(() => {
        return renderEditorStyles(
          templateVersionStore,
          otherEditor.querySelector<HTMLElement>('.avv-container')!
        )
      })
      .catch((e) => {
        console.error(e)
      })
  }

  emitSelection(editor: HTMLElement) {
    const documentID = +editor.dataset.documentId!
    DOMEmit(`toggle-split:change-${documentID}`, {})
  }

  handleArrows(editor: HTMLElement) {
    const forwardArrow = editor.querySelector('.arrow-forward')
    const backArrow = editor.querySelector('.arrow-back')
    if (!backArrow || !forwardArrow) return
    const availableEditors = editor.parentElement!.querySelectorAll(
      "[id*='q-editor'][data-active='true']"
    )
    const availableEditorIds = Array.from(availableEditors).map((e) => e.id)
    const forwardAvailable =
      availableEditorIds.indexOf(editor.id) < availableEditorIds.length - 1
    const backAvailable = availableEditorIds.indexOf(editor.id) > 0
    forwardArrow.classList[forwardAvailable ? 'remove' : 'add'](
      'opacity-50',
      'disabled'
    )
    backArrow.classList[backAvailable ? 'remove' : 'add'](
      'opacity-50',
      'disabled'
    )
  }

  handleEditors() {
    let openedEditor = false
    this.editors.forEach((editor) => {
      if (this.isActiveDocument(editor) && !openedEditor) {
        editor.classList.remove('hidden')
        openedEditor = true
        this.handleArrows(editor)
        this.emitSelection(editor)
      } else {
        editor.classList.add('hidden')
      }
    })
  }

  async sync(e: MouseEvent) {
    if (!IsHTMLElement(e.target)) return
    const button = e.target.closest('#sync-button')!
    if (!IsHTMLElement(button)) return
    const documentID = +button.dataset.documentId!
    const refreshIcon = button.querySelector('.material-symbols-outlined')
    this.setSyncLabel('updating')
    try {
      if(refreshIcon){
        refreshIcon.classList.add('animate-spin')
      }
      const data = await ClipsApi.sync<SyncResponse>({
        params: { id: this.clipIdValue },
        data: { document_id: documentID }
      })
      if (data.agreement_html)
        DOMEmit('agreement_html:update', {
          html: data.agreement_html,
          documentID
        })
    } catch (e) {
      avv_dialog({
        snackMessage: 'Failed to synchronize document',
        snackStyle: 'error'
      })
    }finally{
      if(refreshIcon){
        refreshIcon.classList.remove('animate-spin')
        this.setSyncLabel('updated')
      } 
    }
  }

  toggleSourceText = () => {
    void toggleSourceContexts({
      store: window.qStore,
      forceToggle: true
    })
  }

  setSyncLabel(state: 'needs_sync' | 'updated' | 'updating') {
    const currentActiveLabel = document.querySelector(`.sync-label:not(.hidden)`)
    if (currentActiveLabel) currentActiveLabel.classList.add('hidden')
    const labelToActivate = document.getElementById(`toggle-split:${state}`)
    if (labelToActivate) labelToActivate.classList.remove('hidden')
  }

  isActiveDocument(editor: HTMLElement){
    return editor.dataset.active == 'true'
  }

  get editors(){
    return document.querySelectorAll<HTMLElement>('.avv-editor')
  }

  get activeEditors(){
    return document.querySelectorAll<HTMLElement>('.avv-editor[data-active="true"]')
  }

  get activeEditorIds(){
    return Array.from(this.activeEditors).map((e) => e.id)
  }

  get currentOpenEditor(){
    return document.querySelector<HTMLElement>('.avv-editor:not(.hidden)')
  }

  get currentOpenEditorId(){
    return this.currentOpenEditor?.id ?? 'no_editor_opened'
  }
}

export default ToggleSplitController
